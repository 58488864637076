import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Container, Stack } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import React from 'react'
import { getStrapiImage } from '../../lib/api'

const Conditions: React.FC<Props<Conditions>> = ({ data }) => {
  return (
    <Container sx={{ py: 8 }}>
      <Stack alignItems="center">
        <Typography
          variant="h1"
          textAlign="center"
          fontWeight={800}
          noWrap
          sx={{ width: 1 }}
          color="accent.main"
        >
          {data?.sub}
        </Typography>
        <Typography
          variant="h4"
          textAlign="center"
          fontWeight={700}
          sx={{ mt: '-70px' }}
        >
          {data?.titre}
        </Typography>
      </Stack>
      <Grid container sx={{ pt: 8 }} spacing={8}>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={1}
            >
              <FiberManualRecordIcon fontSize="large" />
              <Typography variant="h5" fontWeight={700}>
                {data?.listeTitre}
              </Typography>
            </Stack>
            <Typography color="text.secondary" sx={{ lineHeight: 2.5 }}>
              {data?.listeDescription}
            </Typography>
            <Stack spacing={3}>
              {data?.liste?.map((el) => (
                <Stack
                  key={el.id}
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                >
                  <CheckCircleIcon color="success" />
                  <Typography color="text.secondary">
                    {el?.condition}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={6} xs={12} sx={{ zIndex: 2 }}>
          <Stack
            sx={{ width: 1, height: 600, position: 'relative' }}
            alignItems="center"
          >
            <Stack
              sx={{
                width: 1,
                height: 340,
                position: 'relative',
                borderRadius: 6,
                overflow: 'hidden',
              }}
            >
              <Image
                alt="Updev Challenge"
                src={getStrapiImage(data?.media1)}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                // placeholder="blur"
                // blurDataURL={getStrapiImagePlaceholder(data?.media1)}
                priority
              />
            </Stack>

            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: {
                  xs: '80%',
                  md: '50%',
                },
                height: 400,
                position: 'relative',
                mt: '-100px',
                borderRadius: 6,
                overflow: 'hidden',
              }}
            >
              <Image
                alt="Updev Challenge"
                src={getStrapiImage(data?.media2)}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                // placeholder="blur"
                // blurDataURL={getStrapiImagePlaceholder(data?.media2)}
                priority
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Conditions
